// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  profile: '/auth/profile',
  setting: "/auth/setting",
  dashboard: "/auth/dashboard"
};

export const PATH_PAGE = {
  home: '/',
  about: '/about-us',
  terms: '/terms',
  privacy: '/privacy',
};

export const PATH_HACKERTHON = {
  exercises: '/exercises',
  practice: '/practices',
  project: '/projects',
  ide: '/online-ide',
  explorer: '/explorer',
  exams: '/exams',
  ranking: '/ranking'
};

export const PATH_RESOURCE = {
  blogs: '/blogs',
  business: '/business',
  questions: '/questions',
  forums: '/forums',
};