import ReactMarkdown from 'react-markdown';
// markdown plugins
import rehypeRaw from 'rehype-raw';
// next
import NextLink from 'next/link';
// @mui
import { Divider, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import hljs from 'highlight.js';
import { useEffect, useState } from 'react';
import Image from './Image';
import LightboxModal from './LightboxModal';

// ----------------------------------------------------------------------

const MarkdownStyle = styled('div')(({ theme }) => {
  const isLight = theme.palette.mode === 'light';

  return {
    // List
    '& ul, & ol': {
      ...theme.typography.body1,
      paddingLeft: theme.spacing(5),
      '& li': {
        lineHeight: 2,
      },
    },

    // Blockquote
    '& blockquote': {
      lineHeight: 1.5,
      fontSize: '1.5em',
      margin: '40px auto',
      position: 'relative',
      fontFamily: 'Roboto, sans-serif',
      padding: theme.spacing(3, 3, 3, 8),
      borderRadius: Number(theme.shape.borderRadius) * 2,
      backgroundColor: theme.palette.background.neutral,
      color: `${theme.palette.text.secondary} !important`,
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
      '& p, & span': {
        marginBottom: '0 !important',
        fontSize: 'inherit !important',
        fontFamily: 'Roboto, sans-serif !important',
        color: `${theme.palette.text.secondary} !important`,
      },
      '&:before': {
        left: 16,
        top: -8,
        display: 'block',
        fontSize: '3em',
        content: '"\\201C"',
        position: 'absolute',
        color: theme.palette.text.disabled,
      },
    },

    // Code Block
    '& pre, & pre > code': {
      ...theme.typography.body1,
      overflowX: 'auto',
      whiteSpace: 'pre',
      padding: theme.spacing(2),
      color: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: `${isLight ? theme.palette.grey[900] : theme.palette.grey[500_16]} !important`,
    },
    '& code': {
      ...theme.typography.body1,
      borderRadius: 4,
      whiteSpace: 'pre',
      padding: theme.spacing(0.2, 0.5),
      color: theme.palette.warning[isLight ? 'darker' : 'lighter'],
      backgroundColor: theme.palette.warning[isLight ? 'lighter' : 'darker'],
      textWrap: 'wrap!important',
      '&.hljs': { padding: 0, backgroundColor: 'transparent' },
    },
    '& .ql-container.ql-snow': {
      borderColor: 'transparent',
      ...theme.typography.body1,
      fontFamily: theme.typography.fontFamily,
    },
    '& .ql-editor': {
      '&.ql-blank::before': {
        fontStyle: 'normal',
        color: theme.palette.text.disabled,
      },
      '& pre.ql-syntax': {
        ...theme.typography.body1,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: isLight ? theme.palette.grey[900] : theme.palette.grey[500_16],
      }
    },
  };
});

// ----------------------------------------------------------------------

export default function Markdown({ ...other }) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      hljs.highlightAll();
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <MarkdownStyle>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} components={components} {...other} />
    </MarkdownStyle>
  );
}

// ----------------------------------------------------------------------

const components = {
  h1: ({ ...props }) => <Typography variant="h1" {...props} style={{ lineHeight: 2 }} />,
  h2: ({ ...props }) => <Typography variant="h2" {...props} style={{ lineHeight: 2 }} />,
  h3: ({ ...props }) => <Typography variant="h3" {...props} style={{ lineHeight: 2 }} />,
  h4: ({ ...props }) => <Typography variant="h4" {...props} style={{ lineHeight: 2 }} />,
  h5: ({ ...props }) => <Typography variant="h5" {...props} style={{ lineHeight: 2 }} />,
  h6: ({ ...props }) => <Typography variant="h6" {...props} style={{ lineHeight: 2 }} />,
  p: ({ ...props }) => <Typography variant="body1" {...props} />,
  hr: ({ ...props }) => <Divider sx={{ my: 3 }} {...props} />,
  img: ({ ...props }) => <ImageBox {...props} />,
  iframe: ({ ...props }) => <iframe width="650" height="366" style={{ maxWidth: '100%' }} {...props} />,
  a: ({ ...props }) =>
    props.href.includes('http') ? (
      <Link target="_blank" rel="noopener" {...props} />
    ) : (
      <NextLink href={props.href} passHref>
        <Link {...props}>{props.children}</Link>
      </NextLink>
    ),
  pre: ({ children, ...props }) => <pre {...props}><code>{children}</code></pre>,
};

function ImageBox(props) {
  const [openLightbox, setOpenLightbox] = useState(false);

  return (
    <>
      <Image alt={props.alt} sx={{ borderRadius: 0, my: 1.5, width: 'auto' }} {...props}
        onClick={() => setOpenLightbox(true)}
      />
      <LightboxModal
        images={[props.src]}
        mainSrc={props.src}
        photoIndex={0}
        setPhotoIndex={0}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </>
  );
}
