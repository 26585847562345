// routes
import { PATH_HACKERTHON, PATH_RESOURCE } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: "menu.exercise",
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_HACKERTHON.exercises,
  },
  // {
  //   title: "menu.exam",
  //   icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
  //   path: PATH_HACKERTHON.exams,
  // },
  {
    title: "menu.practice",
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_HACKERTHON.practice,
  },
  {
    title: "menu.project",
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_HACKERTHON.project,
  },
  {
    title: "menu.ranking",
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_HACKERTHON.ranking,
  },
  {
    title: "menu.blog",
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_RESOURCE.blogs,
  },
  {
    title: "menu.forum",
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_RESOURCE.forums,
  },
  {
    title: "menu.question",
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_RESOURCE.questions,
  },
];

export default menuConfig;
