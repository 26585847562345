// next
import { useRouter } from 'next/router';
// @mui
import { AppBar, Box, Button, Container, Toolbar, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// components
//
import NextLink from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import Label from '../../components/Label';
import Logo from '../../components/Logo';
import useLocales from '../../hooks/useLocales';
import { setOpenDrawer } from '../../redux/slices/hackerthon/hackerthon.exercise';
import AccountPopover from '../main/AccountPopover';
import LanguagePopover from '../main/LanguagePopover';
import navConfig from '../main/MenuConfig';
import MenuDesktop from '../main/MenuDesktop';
import MenuMobile from '../main/MenuMobile';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const { translate } = useLocales();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { openDrawer } = useSelector(state => state.hackerthonExercise);

  const { pathname } = useRouter();

  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/' && false;

  const isPractice = pathname.indexOf('/practices/') !== -1;
  const isExercise = pathname.indexOf('/exercises/') !== -1;
  const isProject = pathname.indexOf('/projects/') !== -1;

  const handleToggle = () => {
    dispatch(setOpenDrawer(!openDrawer));
  };

  return (
    <AppBar sx={{ boxShadow: 2, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {!isDesktop && <>
            <Logo />
          </>}
          <Button onClick={handleToggle}
            sx={{ color: theme.palette.text.primary }}
            startIcon={<Iconify icon={"fluent:list-rtl-16-regular"} sx={{ width: 20, height: 20, color: theme.palette.text.primary }} />}>
            {isExercise && translate('hackerthon.exercise.others')}
            {isPractice && translate('hackerthon.practice.others')}
            {isProject && translate('hackerthon.project.others')}
          </Button>

          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }} >
            {isDesktop && <>
              <Logo />
              <Label color="default" sx={{ ml: 1, cursor: 'pointer' }}>
                <NextLink passHref href="/">
                  <Typography variant='subtitle2'>Hackerthon.Net</Typography>
                </NextLink>
              </Label>
              <LanguagePopover />
            </>}
          </Box>
          {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
          <AccountPopover />
          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
