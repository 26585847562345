import { handleRequest } from '../../utils/axios';

export const getStatisticAPI = async (id) => {
  const config = {
    url: `/statistic/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const updateViewAPI = async (id) => {
  const config = {
    url: `/statistic/${id}/view`,
    method: 'PUT'
  };
  return handleRequest(config);
};

export const updateLikeAPI = async (id) => {
  const config = {
    url: `/statistic/${id}/like`,
    method: 'PUT'
  };
  return handleRequest(config);
};
