/* eslint-disable react/prop-types */
import { Box, Stack, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FacebookIcon, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import Iconify from "./Iconify";

export default function FBComment() {
  const { asPath } = useRouter();
  let url = `https://hackerthon.net${asPath}`;

  useEffect(() => {
    window.FB?.XFBML?.parse();
  }, []);

  return (<div className="fb-comments" data-href={url} data-width="100%" data-numposts="5" />);
}

export function FBLike() {
  useEffect(() => {
    window.FB?.XFBML?.parse();
  }, []);

  return (
    <div className="fb-like" data-href="https://www.facebook.com/trungtamjava" data-width="" data-layout="standard" data-action="like" data-size="large" data-share="true" />
  );
}

export function FBShare({ url, title }) {
  return (
    <FacebookShareButton url={url} quote={title}>
      <Stack direction={"row"} alignItems={"center"} spacing={1} justifyItems={"center"} border={1} borderRadius={1} px={0.5} borderColor={"#04498f"}>
        <FacebookIcon size={20} round={true} />
        <Typography variant="body2">Share</Typography>
      </Stack>
    </FacebookShareButton>
  );
}

export function SocialShare({ title, url }) {
  const { asPath } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  if (!url) url = `https://hackerthon.net${asPath}`;

  const [SOCIALS] = useState([
    {
      name: 'Linkedin',
      icon: <LinkedinShareButton url={url} title={title || url}><Iconify icon="eva:linkedin-fill" width={18} height={18} color="#006097" /></LinkedinShareButton>,
    },
    {
      name: 'Facebook',
      icon: <FacebookShareButton url={url} title={title || url} quote={title || url}><Iconify icon="eva:facebook-fill" width={18} height={18} color="#1877F2" /></FacebookShareButton>
    },
    {
      name: 'Twitter',
      icon: <TwitterShareButton url={url} title={title || url}><Iconify icon="ri:twitter-x-fill" width={15} height={15} color="#333" /></TwitterShareButton>,
    },
    {
      name: 'Link',
      icon: <Box><CopyToClipboard text={url} onCopy={() => enqueueSnackbar('Copied!')}><Iconify icon={'ion:copy'} width={15} height={15} sx={{ cursor: 'pointer' }} /></CopyToClipboard></Box>,
    }
  ]);

  return (
    <Stack direction={"row"} spacing={2} justifyItems={"center"} height={28}>
      {SOCIALS.map((action) => (
        <Fragment key={action.name}>
          {action.icon}
        </Fragment>
      ))}
    </Stack>
  );
}