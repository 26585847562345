import PropTypes from 'prop-types';
// next
// @mui
import { Box, Stack } from '@mui/material';
// components
//
import DrawerExercises from '../../sections/exercise/DrawerExercises';
import MainFooter from '../main/MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

FullScreenLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function FullScreenLayout({ children }) {

  return (
    <Stack>
      <DrawerExercises />
      <MainHeader />
      {children}
      <Box sx={{ flexGrow: 1 }} />
      <MainFooter />
    </Stack>
  );
}
