import PropTypes from 'prop-types';
// guards
// components
import LogoOnlyLayout from './LogoOnlyLayout';
import FullScreenLayout from './fullscreen';
import MainLayout from './main';

// ----------------------------------------------------------------------

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['main', 'logoOnly', 'fullscreen']),
};

export default function Layout({ variant, children }) {
  if (variant === 'logoOnly') {
    return <LogoOnlyLayout> {children} </LogoOnlyLayout>;
  }

  if (variant === 'fullscreen') {
    return <FullScreenLayout> {children} </FullScreenLayout>;
  }

  return <MainLayout>{children}</MainLayout>;
}
