import { AnimatePresence, m } from 'framer-motion';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
// @mui
import { Backdrop, Divider, FormControlLabel, Radio, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// hooks
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { NAVBAR } from '../../config';
//
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import { IconButtonAnimate, varFade } from '../../components/animate';
import useLocales from '../../hooks/useLocales';
import { setOpenDrawer } from '../../redux/slices/hackerthon/hackerthon.exercise';
import { OtherPracticeTable } from '../practice/PracticeDetail';
import { OtherProjectTable } from '../project/ProjectDetail';
import { OtherExerciseTable } from './ExerciseDetail';
// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
  top: 0,
  left: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  width: 300,
  flexDirection: 'column',
  margin: theme.spacing(0),
  paddingBottom: theme.spacing(3),
  zIndex: theme.zIndex.drawer + 4,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  boxShadow: `24px 12px 32px 4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16
  )}`,
}));

// ----------------------------------------------------------------------

export default function DrawerExercises() {
  const { openDrawer: open } = useSelector(state => state.hackerthonExercise);
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const { pathname } = useRouter();
  const isPractice = pathname.indexOf('/practices/') !== -1;
  const isExercise = pathname.indexOf('/exercises/') !== -1;
  const isProject = pathname.indexOf('/projects/') !== -1;

  const varSidebar =
    varFade({
      distance: NAVBAR.BASE_WIDTH,
      durationIn: 0.32,
      durationOut: 0.32,
    }).inLeft;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  const handleClose = () => {
    dispatch(setOpenDrawer(false));
  };

  return (
    <>
      <Backdrop
        open={open}
        onClick={handleClose}
        sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />
      <AnimatePresence>
        {open && (
          <RootStyle {...varSidebar}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
              <Typography variant="subtitle1">{translate('hackerthon.exercise.others')}</Typography>
              <div>
                <IconButtonAnimate onClick={handleClose}>
                  <Iconify icon={'eva:close-fill'} width={20} height={20} />
                </IconButtonAnimate>
              </div>
            </Stack>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Scrollbar sx={{ flexGrow: 1 }}>
              {isExercise && <OtherExerciseTable />}
              {isPractice && <OtherPracticeTable />}
              {isProject && <OtherProjectTable />}
            </Scrollbar>
          </RootStyle>
        )}
      </AnimatePresence>
    </>
  );
}

// ----------------------------------------------------------------------

BoxMask.propTypes = {
  value: PropTypes.string,
};

export function BoxMask({ value }) {
  return (
    <FormControlLabel
      label=""
      value={value}
      control={<Radio sx={{ display: 'none' }} />}
      sx={{
        m: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
      }}
    />
  );
}
