/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Link, Stack, Table, TableBody, TableCell, TablePagination, TableRow, Typography, useTheme } from '@mui/material';
import NextLink from 'next/link';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { SocialShare } from '../../components/FBSocial';
import Iconify from '../../components/Iconify';
import Label from '../../components/Label';
import Markdown from '../../components/Markdown';
import Scrollbar from '../../components/Scrollbar';
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
import { getProjects, setProjectSearch } from '../../redux/slices/hackerthon/hackerthon.project';
import { useDispatch, useSelector } from '../../redux/store';
// import { createBookmarkAPI, deleteBookmarkAPI, getBookmarkAPI } from '../../service/hackerthon/hackerthon.bookmark.service';
import { getStatisticAPI, updateLikeAPI, updateViewAPI } from '../../service/hackerthon/hackerthon.statistic.service';
import { fShortenNumber } from '../../utils/formatNumber';
// @mui
import hljs from 'highlight.js';

export function ProjectDescription() {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const { isAuthenticated } = useAuth();
  const { project } = useSelector((state) => state.hackerthonProject);

  // const [bookmark, setBookmark] = useState(null);
  const [statistic, setStatistic] = useState({ viewNo: 0, likeNo: 0 });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (project) {
        updateView();
        getStatistic();
        hljs.highlightAll();
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [project?.id]);

  const updateView = async () => {
    updateViewAPI(project?.statistic?.id);
  };

  const handleLike = async () => {
    if (!isAuthenticated) {
      enqueueSnackbar(translate("label.signinToJmaster"), { variant: 'error' });
      return;
    }
    setStatistic({ ...statistic, likeNo: statistic.likeNo + 1 });
    updateLikeAPI(project?.statistic?.id);
  };

  const getStatistic = async () => {
    const { code, data } = await getStatisticAPI(project?.statistic?.id);
    (code === '200') && setStatistic(data);
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     isAuthenticated && isBookmarked();
  //   }, 500);
  //   return () => clearTimeout(timeout);
  // }, [project, isAuthenticated]);

  // const isBookmarked = async () => {
  //   const { code, data } = await getBookmarkAPI(project?.id);
  //   if (code === '200') setBookmark(data);
  //   else setBookmark(null);
  // };

  // const handleBookmark = async () => {
  //   if (!isAuthenticated) {
  //     enqueueSnackbar(translate("label.signinToJmaster"), { variant: 'error' });
  //     return;
  //   }

  //   if (bookmark) {
  //     let { code } = await deleteBookmarkAPI(bookmark.id);

  //     if (code === "200") {
  //       setBookmark(null);
  //       enqueueSnackbar(translate("message.bookmarkCancel"));
  //     }
  //   } else {
  //     const request = {
  //       exercise: { id: exercise?.id }
  //     };
  //     let { code, data } = await createBookmarkAPI(request);

  //     if (code === "200") {
  //       setBookmark(data);
  //       enqueueSnackbar(translate("message.bookmarkSuccess"));
  //     }
  //   }
  // };

  return (
    <Scrollbar sx={{ flexGrow: 1, p: 1 }}>
      <Stack spacing={1}>
        <Stack mb={1} spacing={0.25}>
          <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
            <Typography variant="subtitle1">
              #{project?.id}. {project?.title}
            </Typography>
            {/* <IconButton size="small" color="default" onClick={handleBookmark}>
              <Iconify icon={bookmark ? "material-symbols:bookmark" : "material-symbols:bookmark-outline"} width={18} height={18} />
            </IconButton> */}
          </Stack>
          <Stack direction={'row'} spacing={2} alignItems={"center"}>
            <SocialShare title={project?.title} />
            <Stack direction={'row'} alignItems={"center"} spacing={0.8}>
              <Typography variant="caption" >{fShortenNumber(statistic.viewNo)}</Typography>
              <Iconify icon={"mdi:eye-outline"} sx={{ height: 15, width: 15, }} />
              <Typography variant="caption" >{fShortenNumber(statistic.likeNo)}</Typography>
              <Iconify icon={"iconamoon:like-light"} sx={{ height: 15, width: 15, cursor: 'pointer' }} onClick={handleLike} />
            </Stack>
          </Stack>
          <Divider sx={{ my: 1 }} />
          {/* <Stack direction={'row'} spacing={2}>
            <Typography variant="body2">
              {translate('hackerthon.exercise.submission')}:{' '}
              <Typography variant="subtitle2" component="span">
                {fNumber(project?.answerNo)}
              </Typography>
            </Typography>

          </Stack>
          <Divider sx={{ my: 1 }} /> */}
        </Stack>

        <div className="ql-snow">
          <div className="ql-editor" style={{ padding: 0 }}>
            <Markdown children={project?.description} />
          </div>
        </div>

        <Divider sx={{ my: 1 }} />
        <Box>
          {project?.tags?.map((tag) => (
            <NextLink key={tag.id} href={`/tag/${tag.slug}`} passHref>
              <Label sx={{ mr: 1, cursor: 'pointer' }}>{tag.title}</Label>
            </NextLink>
          ))}
        </Box>

        <Divider sx={{ my: 1 }} />
        <Typography variant="subtitle1">{translate('hackerthon.project.others')}:</Typography>
        <OtherProjects />
      </Stack>
    </Scrollbar >
  );
}

export function OtherProjects() {
  const dispatch = useDispatch();

  const { search, project } = useSelector((state) => state.hackerthonProject);

  useEffect(() => {
    handleFilterByTags(project?.tags);
    return () => handleFilterByTags(null);
  }, [project?.tags]);

  const handleFilterByTags = (tags) => {
    const tagIds = tags?.filter(t => (t.type !== 'LEVEL' && t.type !== 'CATEGORY'))?.map(t => t.id).toString();
    dispatch(setProjectSearch({ ...search, page: 0, tags, filterBys: { ...search.filterBys, tagIds, levelId: null } }));
  };

  return (
    <OtherProjectTable />
  );
}

export function OtherProjectTable() {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { projects, search, totalElements } = useSelector((state) => state.hackerthonProject);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(getProjects());
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      setProjectSearch({
        ...search,
        page: 0,
        size: parseInt(event.target.value, 10),
      })
    );
  };

  const handleChangePage = (_, page) => {
    dispatch(
      setProjectSearch({
        ...search,
        page,
      })
    );
  };

  return (
    <Box>
      <Table>
        <TableBody>
          {projects.map((row) => (
            <TableRow hover key={row.id} tabIndex={-1}>
              <TableCell >
                <NextLink href={`/projects/${row.slug}`} passHref>
                  <Link href='#' variant='subtitle2' color={theme.palette.text.primary}>
                    #{row.id}. {row.title}
                  </Link>
                </NextLink>
              </TableCell>
              <TableCell>
                <Label color={
                  (row.level.title === 'Dễ' && 'primary') ||
                  (row.level.title === 'Trung bình' && 'warning') ||
                  (row.level.title === 'Khó' && 'error') ||
                  'default'
                }>{row.level.title}</Label>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalElements}
        rowsPerPage={search.size}
        page={search.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage} />
    </Box >
  );
}